import {createStore} from 'vuex'
import {locales} from "@/app/trans/js";
import createMultiTabState from 'vuex-multi-tab-state';
import {Http, setHttpToken} from "@/app/plugins/axios";

export default createStore({
  state: {
    sidebarVisible: '',
    sidebarUnfoldable: false,
    pageLoading: false,
    currentUser: null,
    userToken: '',
  },
  mutations: {
    togglePageLoading(sate, flag) {
      sate.pageLoading = flag
    },
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    }
  },
  actions: {
    setAppLocale({commit, state}, lang) {
      locales.value = Array.isArray(lang) ? lang : [lang];
      localStorage.setItem('user-locale', lang)

      location.reload()
    },
    setCurrentUser({commit, state}, data) {
      if (data.user)
        state.currentUser = data.user

      if (data.token) {
        state.userToken = data.token
        localStorage.setItem('userToken', state.userToken)
        setHttpToken();
      }

    },
    getUserInfo({commit, state}) {
      return new Promise((resolve, reject) => {
        Http.get('/user/my-profile').then(res => {
          if (res.data.data) {
            state.currentUser = res.data.data
            resolve(state.currentUser)
          } else {
            reject(false)
          }

        }).catch(err => {
          reject(err)
        })
      })
    },
    logout({commit, state}) {
      state.currentUser = null
      state.userToken = ''
      localStorage.removeItem('userToken')
    }
  },
  modules: {},
  plugins: [
    createMultiTabState(),
  ],
})
