import {l} from "@/app/trans/js";

export const breadcrumb = {
  Home: l({
    vi: 'Home',
    en: 'Home',
    ja: 'ホーム'
  }),
  Dashboard: l({
    vi: 'Dashboard',
    en: 'Dashboard',
    ja: 'ダッシュボード'
  }),
  user: l({
    vi: 'Quản lý người dùng',
    en: 'User management',
    ja: 'ユーザー管理'
  }),
  'user.list': l({
    vi: 'Danh sách tài khoản',
    en: 'List of accounts',
    ja: 'アカウント一覧'
  }),
  'user.add': l({
    vi: 'Thêm tài khoản',
    en: 'Add new account',
    ja: 'アカウント追加'
  }),
  'user.edit': l({
    vi: 'Sửa thông tin tài khoản',
    en: 'Edit account information',
    ja: 'アカウント情報を編集する'
  }),
  category: l({
    vi: 'Quản lý chuyên mục',
    en: 'Category Manager',
    ja: '投稿管理'
  }),
  'category.index': l({
    vi: 'Quản lý chuyên mục',
    en: 'Category Manager',
    ja: 'カテゴリー管理'
  }),
  product: l({
    vi: 'Post',
    en: 'Post ',
    ja: 'ポスト'
  }),
  'product.list': l({
    vi: 'Danh sách sản phẩm',
    en: 'Product list',
    ja: '投稿一覧'
  }),
  'product.pending.list': l({
    vi: 'Post đợi duyệt',
    en: 'Product list pending',
    ja: '承認待ちの投稿'
  }),
  order: l({
    vi: 'Đơn hàng',
    en: 'Order',
    ja: '注文管理'
  }),
  'order.list': l({
    vi: 'Danh sách đơn hàng',
    en: 'Order list',
    ja: '注文一覧'
  }),
  'order.detail': l({
    vi: 'Chi tiết đơn hàng',
    en: 'Order detail',
    ja: '注文詳細'
  }),
  'user.report': l({
    vi: 'Danh sách người dùng bị report',
    en: 'List user reported',
    ja: '通報ユーザー一覧'
  }),
  revenue: l({
    vi: 'Quản lý doanh thu',
    en: 'Revenue',
    ja: '売上管理'
  }),
  'revenue.list': l({
    vi: 'Danh sách thanh toán',
    en: 'List revenue',
    ja: '決済一覧'
  }),
  'revenue.dashboard': l({
    vi: 'Tổng quan',
    en: 'List revenue',
    ja: '一般'
  }),
  notification: l({
    vi: 'Thông báo',
    en: 'Notification',
    ja: 'システムからのお知らせ'
  }),
  'cog.default': l({
    vi: 'Config default',
    en: 'Config default',
    ja: '一般設定'
  }),
  'cog.area': l({
    vi: 'Config area',
    en: 'Config area',
    ja: '地域設定'
  }),
  'user.password': l({
    vi: 'password',
    en: 'Config area',
    ja: 'パスワード再設定'
  }),
  'product.detail': l({
    vi: "Thông tin bài post",
    en: "Product information",
    ja: "投稿情報"
  }),
}

export const action = {
  confirm: l({
    vi: 'Xác nhận',
    en: 'Confirm',
    ja: '確かめる'
  }),
  edit: l({
    vi: 'Sửa',
    en: 'Edit',
    ja: '更新'
  }),
}

export const app = {
  notification: l({
    vi: 'Thông báo',
    en: 'Notify',
    ja: '通知する'
  }),
  passwordCopy: l({
    vi: 'Mật khẩu đã được tạo tự động và copy',
    en: 'Password has been automatically generated and copied',
    ja: 'パスワードは自動的に生成され、コピーされました'
  }),
  selectCountry: l({
    vi: 'Chọn quốc gia',
    en: 'Select country',
    ja: '国を選択',
  }),
  deleteConfirm: l({
    vi: 'Bạn chắc chắn muốn xóa chứ ?',
    en: 'You are confirm delete ?',
    ja: '通知する'
  }),
  address: l({
    vi: 'Địa chỉ',
    en: 'Address',
    ja: '住所'
  }),
  zipCode: l({
    vi: 'Mã bưu điện',
    en: 'Zip code',
    ja: '郵便番号'
  })
}
