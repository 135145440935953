export class HeaderTrigger {
  boxTopPage = false

  constructor(name, value) {
    if (name)
      this[name] = value
  }
}

HeaderTrigger.BOX_TOP_PAGE = 'boxTopPage'
