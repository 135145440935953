import Aes from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";

class DataEncrypt {
  encryptData(data) {
    if (Array.isArray(data) || typeof data == 'object')
      data = JSON.stringify(data)

    return Aes.encrypt(data, self.SK).toString()
  }

  decryptData(ciphertext, parse = false) {
    const bytes = Aes.decrypt(ciphertext, self.SK)

    return parse ? JSON.parse(bytes.toString(Utf8)) : bytes.toString(Utf8);
  }
}

DataEncrypt.SK = "15a284b6-f277-4b80-bc7c-00c453e2a929"

export default new DataEncrypt()
