import {l} from "@/app/trans/js";

export const listUser = {
  name: l({
    vi: "Họ tên",
    en: "Full name",
    ja: "ニックネーム"
  }),
  email: l({
    vi: "Địa chỉ mail",
    en: "Mailing address",
    ja: "メールアドレス"
  }),
  phone: l({
    vi: "Điện thoại",
    en: "Phone",
    ja: "電話番号"
  }),
  address: l({
    vi: "Địa chỉ",
    en: "Address",
    ja: "住所"
  }),
  status: l({
    vi: "Trạng thái",
    en: "Status",
    ja: "状態"
  }),
  changePass: l({
    vi: 'Đặt lại mật khẩu',
    en: 'Reset Password',
    ja: 'パスワード再設定'
  }),
  delete: l({
    vi: 'Xóa tài khoản này',
    en: 'Delete this account',
    ja: 'アカウント削除'
  }),
  cardId: l({
    vi: 'Xác thực thông tin cá nhân',
    en: 'Verify personal information',
    ja: '個人情報の認証'
  }),
  confirmId: l({
    vi: 'Đồng ý',
    en: 'Ok',
    ja: '認証'
  }),
  rejectId: l({
    vi: 'Từ chối',
    en: 'Reject',
    ja: '拒否'
  }),
  phoneVerify0: l({
    vi: 'Chờ duyệt',
    en: 'PENDING_VERIFY',
    ja: '未認証'
  }),
  phoneVerify1: l({
    vi: 'Đã duyệt',
    en: 'Approved',
    ja: '認証済み'
  }),
  phoneVerify2: l({
    vi: 'Chưa duyệt',
    en: 'Denied',
    ja: '未認証'
  }),
  createdAt: l({
    vi: 'Ngày tạo',
    en: 'Created At',
    ja: '投稿日'
  }),
  reason: l({
    vi: 'Lý do',
    en: 'Reason',
    ja: 'Lý do'
  }),
  search: l({
    vi: 'Tìm kiếm',
    en: 'Search',
    ja: '検索'
  })
}

export const addUser = {
  title: l({
    vi: 'Tạo tài khoản mới',
    en: 'Create a new account',
    ja: '新しいアカウントを作成する'
  }),
  titleUpdate: l({
    vi: 'Cập nhật thông tin tài khoản',
    en: 'Update account information',
    ja: 'アカウント情報を更新する'
  }),
  titleDefault: l({
    vi: 'Thông tin bắt buộc',
    en: 'Required Information',
    ja: '必要な情報'
  }),
  titleInfo: l({
    vi: 'Thông tin thêm',
    en: 'More information',
    ja: '詳しくは'
  }),
  titleDetail: l({
    vi: 'Thông tin chi tiết',
    en: 'Detail information',
    ja: 'プロフィール'
  }),
  name: l({
    vi: 'Họ tên',
    en: 'Full name',
    ja: 'フルネーム'
  }),
  nameKana: l({
    vi: 'Họ tên kana',
    en: 'Name kana',
    ja: '名前かな'
  }),
  email: l({
    vi: 'Địa chỉ email',
    en: 'Email address',
    ja: '電子メールアドレス'
  }),
  password: l({
    vi: 'Mật khẩu',
    en: 'Password',
    ja: 'パスワード'
  }),
  phone: l({
    vi: 'Điện thoại',
    en: 'Phone',
    ja: '電話'
  }),
  address: l({
    vi: 'Địa chỉ',
    en: 'Address',
    ja: '住所'
  }),
  status: l({
    vi: 'Trạng thái',
    en: 'Status',
    ja: '状態'
  }),
  status1: l({
    vi: 'Đang hoạt động',
    en: 'Status',
    ja: '状態'
  }),
  status2: l({
    vi: 'Ngừng hoạt động',
    en: 'Status',
    ja: '状態'
  }),
  status3: l({
    vi: 'Đang khóa',
    en: 'Status',
    ja: '状態'
  }),
  role: l({
    vi: 'Loại tài khoản',
    en: 'Account Type',
    ja: '口座の種類'
  }),
  role1: l({
    vi: 'Tài khoản thường',
    en: 'Regular account',
    ja: '通常のアカウント'
  }),
  role2: l({
    vi: 'Tài khoản admin',
    en: 'Admin account',
    ja: '管理者アカウント'
  }),
  roleTitle: l({
    vi: 'Quyền hạn của tài khoản này',
    en: 'Permissions of this account',
    ja: 'このアカウントの権限'
  }),
  gender: l({
    vi: 'Giới tính',
    en: 'Gender',
    ja: '性別'
  }),
  male: l({
    vi: 'Nam',
    en: 'Male',
    ja: '男性'
  }),
  female: l({
    vi: 'Nữ',
    en: 'Female',
    ja: '女性'
  }),
  age: l({
    vi: 'Tuổi',
    en: 'Age',
    ja: '年代'
  }),
  verify: l({
    vi: 'Xác thực',
    en: 'Verify',
    ja: '身分証認証'
  }),
  phoneVerify: l({
    vi: 'Xác thực điện thoại',
    en: 'Phone Verify',
    ja: '電話番号'
  }),
  area: l({
    vi: 'Địa chỉ',
    en: 'Area',
    ja: '住所'
  }),
  type: l({
    vi: 'Thể loại',
    en: 'Type',
    ja: '種類'
  }),
  level: l({
    vi: 'Level',
    en: 'Level',
    ja: '部門'
  }),
  height: l({
    vi: 'Chiều cao',
    en: 'Height',
    ja: '身長'
  }),
  classLocation: l({
    vi: 'Địa điểm học',
    en: 'Class location',
    ja: 'レッスンの場所'
  }),
  danceAge: l({
    vi: 'Dance Age',
    en: 'Dance Age',
    ja: '年代'
  }),
  experience: l({
    vi: 'Kinh nghiệm',
    en: 'Experience',
    ja: 'ダンス歴'
  }),
  bio: l({
    vi: 'Bio',
    en: 'Bio',
    ja: '自己 P R や過去の成績、その他補足'
  }),
  danceBirthday: l({
    vi: 'Dance Birthday',
    en: 'Dance Birthday',
    ja: '生年月日'
  }),
  gakuren: l({
    vi: 'Gakuren',
    en: 'Gakuren',
    ja: 'Gakuren'
  }),
  dance: l({
    vi: 'Dance',
    en: 'Dance',
    ja: 'ダンスの写真'
  }),
  titleDanceInfo: l({
    vi: 'Kinh nghiệm khiêu vũ',
    en: 'Dance experience',
    ja: 'ダンスに関する経験'
  }),
  identityCard: l({
    vi: 'Chứng minh thư nhân dân',
    en: 'IDENTITY CARD',
    ja: 'マイナンバーカード'
  }),
  drivingLicense: l({
    vi: 'Bằng lái xe',
    en: 'DRIVING LICENSE',
    ja: '免許証'
  }),
  passPort: l({
    vi: 'Hộ chiếu',
    en: 'PASSPORT',
    ja: 'パスポート'
  }),
  validate: {
    nickname: {
      required: l({
        vi: 'Bắt buộc nickname',
        en: 'Nick name is required',
        ja: 'ユーザー名が必要です'
      }),
      maxLength: l({
        vi: 'Tên tài khoản không được vượt quá 200 ký tự',
        en: 'Username cannot exceed 200 characters',
        ja: 'ユーザー名は200文字を超えることはできません'
      })
    },
    email: {
      required: l({
        vi: 'Bắt buộc nhập email',
        en: 'Required to enter email',
        ja: 'メールを入力する必要があります'
      }),
      email: l({
        vi: 'Địa chỉ email không hợp lệ',
        en: 'Email address is not valid',
        ja: 'メールアドレスが無効です'
      })
    },
    password: {
      required: l({
        vi: 'Bắt buộc nhập mật khẩu',
        en: 'Password required',
        ja: 'パスワードが必要',
      }),
      minLength: l({
        vi: 'Mật khẩu phải từ 6 ký tự trở lên',
        en: 'Password must be 6 or more characters',
        ja: 'パスワードは6文字以上である必要があります',
      })
    }
  }
}
