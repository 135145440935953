import axios from 'axios'
import DataEncrypt from "@/app/data-encrypt";

const Http = axios.create({
  baseURL: process.env.VUE_APP_APP_API_URL,
  timeout: 100000,
  headers: {
    'X-Author': 'diengv.dev@gmail.com'
  },
  withCredentials: true
});

export function setHttpToken() {
  const token = localStorage.getItem('userToken')

  if (token)
    Http.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}

setHttpToken();

export default {
  install(app, options) {

    Http.interceptors.request.use(function (config) {
      // Do something before request is sent
      return config;
    }, function (error) {
      // Do something with request error
      return Promise.reject(error);
    });

    // Add a response interceptor
    Http.interceptors.response.use(function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    }, function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    });


    app.config.globalProperties.$http = Http
  }
}

export {Http, axios}
