import {defineComponent, h} from 'vue';
import {CNavLink} from './CNavLink';

const CNavItem = defineComponent({
  name: 'CNavItem',
  props: Object.assign({}, CNavLink.props),
  setup(props, {slots}) {
    return () => h('li', {
      class: 'nav-item',
    }, props.href
      ? h('a', {
        class: [{
          'nav-link': true,
          active: props.active,
          disabled: props.disabled,
        }],
        href: props.href,
        onClick(event) {
          event.preventDefault();
        },
      }, {
        default: () => slots.default && slots.default(),
      }) : slots.default && slots.default());
  },
});
export {CNavItem};
