<template>
  <div class="submit-btn">
    <button type="button" class="btn" :class="'btn-'+color" v-if="!isLoading">
      <slot></slot>
    </button>
    <div v-if="isLoading" class="spinner-grow text-warning" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubmitBtn",
  props: {
    color: {
      type: String,
      default: '-default'
    },
    disable: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.submit-btn {
  display: inline-block;
}
</style>
