<template>
  <i :class="['app-icon', name, customClassName]"></i>
</template>

<script>
export default {
  name: "AppIcon",
  props: {
    name: String,
    customClassName: String
  }
}
</script>

<style scoped>

</style>
