export const API_URL = process.env.VUE_APP_APP_API_URL
export const API_URL_PROD = 'https://jimoty-api.kaiyouit.com'

export const CONSTANTS = {
  MESSAGE: {
    TEXT: 'TEXT',
    IMAGE: 'IMAGE',
    AUDIO: 'AUDIO',
    VIDEO: 'VIDEO',
  },
  SOCIAL: {
    GOOGLE: 'google',
    FACEBOOK: 'facebook',
    APPLE: 'apple',
  },
  IDENTITY: {
    NOT_VERIFY: 1,
    PENDING_VERIFY: 2,
    VERIFIED: 3,
    REJECT: 4
  },
  GENDER: {
    ALL: 1,
    MALE: 2,
    FEMALE: 3
  },
  TYPE_IDENTITY_CARD: {
    IDENTITY_CARD: 1,
    DRIVING_LICENSE: 2,
    PASSPORT: 3
  },
  CATEGORY_TYPE: {
    LEADER: "Leader",
    PARTNER: "Partner",
    TEACHER: "Teacher",
    SECOND_HAND: "SecondHand",
    SPACE: "Space",
    EVENT: "Event"
  },
  PAYMENT_METHOD: {
    CASH_ON_DELIVERY: 1,
    BANK_CARD: 2
  },
  PAYMENT_STATUS: {
    UNPAID: 1,
    PAID: 2
  },
  LIMIT: 20,
  SKIP: 0,
  PLACE_OF_DANCE: {
    ALL: 1,
    STUDIO_OF_TEACHER: 2,
    FREE_PLACE: 3
  },
  POST_DATE: {
    ALL: 0,
    ONE_DAY: 1,
    SEVEN_DAY: 2,
    ONE_MONTH: 3
  },
  STATUS_SPACE: {
    PENDING: 1,
    DONE: 2
  },
  ORDER_STATUS: {
    ALL: 0,
    PENDING: 1,
    ACCEPTED: 2,
    DELIVERY: 3,
    DONE: 4,
    CANCEL: 5
  },
  AGE: {
    U20: 1,
    U30: 2,
    U40: 3
  },
  GOAL_OF_DANCE: {
    EXPERTS: 1,
    AMATEUR: 2,
    SOMETIME: 3,
    SELDOM: 4,
  }
}

export const TEXT_MAP = {
  Gender: {
    1: '男性',
    2: '女性'
  },
  Height: {
    1: "〜150cm",
    2: "151〜160cm 未満",
    3: "160〜170cm 未満",
    4: "170〜180cm 未満",
    5: "180〜190cm 未満",
  },
  Level: {
    1: 'プロ',
    2: 'アマ',
    3: '学連',
    4: '問わない'
  },
  Age: {
    '1': '18 歳未満(保護者の方のみ)',
    '2': '18歳〜20歳',
    '3': '18歳~',
    '4': '20代前半',
    '5': '20代後半',
    '6': '30代前半',
    '7': '30代後半',
    '8': '40代前半',
    '9': '40代後半',
    '10': '50代前半',
    '11': '50代後半',
    '12': '60代前半',
    '13': '60代後半',
    '14': '70代前半',
    '15': '70代後半',
    '16': '80代前半',
    '17': '80代後半',
    '18': '90代前半',
    '19': '90代以上'
  },
  GoalOfDance: {
    1: '競技会出場',
    2: '練習のみ',
    3: '運動不⾜解消',
    4: '１回切り〜不定期',
    5: '相談'
  },
  DanceType: {
    "1": "ラテン",
    "2": "スタン",
    "3": "両方"
  },
  DanceLevel: {
    "1": "アマチュア",
    "2": "プロ",
    "3": "学連",
    "4": "その他"
  },
  DanceAge: {
    '1': '初心者',
    '2': '1〜3年',
    '3': '3年〜5年',
    '4': '5年〜10年',
    '5': '10年以上'
  }
}

export const ADMIN_DEFAULT_PER_PAGE = 20
