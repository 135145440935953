import {l} from "@/app/trans/js";


export const listCategory = {
  name: l({
    vi: "Tên chuyên mục",
    en: "Category name",
    ja: "名前と苗字"
  }),
  slug: l({
    vi: "Slug",
    en: "Slug",
    ja: "送り先"
  }),
  parent: l({
    vi: "Chuyên mục cha",
    en: "Category parent",
    ja: "電話"
  }),
  description: l({
    vi: "Mô tả",
    en: "Description",
    ja: "住所"
  }),
  status: l({
    vi: "Trạng thái",
    en: "Status",
    ja: "状態"
  }),
  edit: l({
    vi: 'Sửa',
    en: 'Edit',
    ja: 'パスワードを再設定する'
  }),
  delete: l({
    vi: 'Xóa',
    en: 'Delete',
    ja: 'このアカウントを削除する'
  }),
  cardId: l({
    vi: 'Xác thực thông tin cá nhân',
    en: 'Verify personal information',
    ja: '個人情報を確認する'
  }),
}

export const categoryManager = {
  type: l({
    vi: 'Loại chuyên mục',
    en: 'Category type',
    ja: 'カテゴリタイプ'
  }),
  title: l({
    vi: 'Quản lý chuyên mục',
    en: 'Category Manager',
    ja: '新しいアカウントを作成する'
  }),
  titleDefault: l({
    vi: 'Thêm chuyên mục',
    en: 'Add categories',
    ja: 'カテゴリを追加'
  }),
  titleEdit: l({
    vi: 'Sửa thông tin chuyên mục',
    en: 'Edit Category Info',
    ja: '新しいアカウントを作成する'
  }),
  name: l({
    vi: 'Tên chuyên mục',
    en: 'Category Name',
    ja: '新しいアカウントを作成する'
  }),
  parent: l({
    vi: 'Chuyên mục cha',
    en: 'Category Parent',
    ja: '新しいアカウントを作成する'
  }),
  description: l({
    vi: 'Mô tả ngắn',
    en: 'Description',
    ja: '新しいアカウントを作成する'
  }),
  validate: {
    name: {
      required: l({
        vi: 'Bắt buộc nhập tên chuyên mục',
        en: 'Category name is required',
        ja: 'ユーザー名が必要です'
      }),
      maxLength: l({
        vi: 'Tên chuyên mục không được vượt quá 200 ký tự',
        en: 'Category name cannot exceed 200 characters',
        ja: 'ユーザー名は200文字を超えることはできません'
      })
    },
  }
}
