import {l} from "@/app/trans/js";


export const addProduct = {
  title: l({
    vi: "Thêm sản phẩm",
    en: "Create product",
    ja: "名前と苗字"
  }),
  titleBasic: l({
    vi: "Thông tin cơ bản",
    en: "Basic Information",
    ja: "名前と苗字"
  }),
  name: l({
    vi: "Tên sản phẩm",
    en: "Product name",
    ja: "名前と苗字"
  }),
  content: l({
    vi: "Nội dung",
    en: "Content",
    ja: "名前と苗字"
  }),
}

export const listProduct = {
  title: l({
    vi: "Danh sách sản phẩm",
    en: "List product",
    ja: "名前と苗字"
  }),
  titleBasic: l({
    vi: "Thông tin cơ bản",
    en: "Basic Information",
    ja: "名前と苗字"
  }),
  name: l({
    vi: "Tên sản phẩm",
    en: "Product name",
    ja: "名前と苗字"
  }),
  content: l({
    vi: "Nội dung",
    en: "Content",
    ja: "名前と苗字"
  }),
}

export const products = {
  detail: l({
    vi: "Thông tin sản phẩm",
    en: "Product information",
    ja: "製品情報"
  }),
  title: l({
    vi: "Tiêu đề",
    en: "Title",
    ja: "タイトル"
  }),
  description: l({
    vi: "Nội dung",
    en: "content",
    ja: "名前と苗字"
  }),
  category: l({
    vi: "Chuyên mục",
    en: "category",
    ja: "カテゴリー"
  }),
  startDate: l({
    vi: "Ngày bắt đầu",
    en: "Start Date",
    ja: "名前と苗字"
  }),
  endDate: l({
    vi: "Ngày kết thúc",
    en: "End Date",
    ja: "名前と苗字"
  }),
  dateExpired: l({
    vi: "Hạn đăng ký",
    en: "Registration Deadline",
    ja: "名前と苗字"
  }),
  price: l({
    vi: "Phí tham gia",
    en: "Price",
    ja: "価格"
  }),
  province: l({
    vi: "Tỉnh / Thành phố",
    en: "province",
    ja: "名前と苗字"
  }),
  district: l({
    vi: "Quận / Huyện",
    en: "district",
    ja: "名前と苗字"
  }),
  detailAddress: l({
    vi: "Địa chỉ",
    en: "Address",
    ja: "名前と苗字"
  }),
  createdAt: l({
    vi: "Ngày đăng",
    en: "Created at",
    ja: "投稿日"
  }),
  image: l({
    vi: "Hình ảnh",
    en: "Image",
    ja: "画像"
  })
}
export const searchBox = {
  key: l({
    vi: "Từ khóa",
    en: "Key",
    ja: "Key word"
  }),
  category: l({
    vi: "Chuyên mục",
    en: "Category",
    ja: "Category"
  }),
  startDate: l({
    vi: "Ngày bắt đầu",
    en: "Start Date",
    ja: "から"
  }),
  endDate: l({
    vi: "Ngày kết thúc",
    en: "End Date",
    ja: "まで"
  }),
  search: l({
    vi: "Tìm kiếm",
    en: "Search",
    ja: "検索"
  })
}
