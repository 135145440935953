<template>
  <CBreadcrumb class="d-md-down-none me-auto mb-0">
    <router-link v-for="item in breadcrumbs" :to="{ name: item.name}" custom
                 v-slot="{ href, route, navigate, isActive, isExactActive }">
      <li class="breadcrumb-item" :class="{'active':isActive && isExactActive}" @click="navigate">
        <span v-if="isActive && isExactActive">{{ $t.breadcrumb[item.name] ? $t.breadcrumb[item.name] : item.name }}</span>
        <a :href="href" v-else>{{ $t.breadcrumb[item.name] ? $t.breadcrumb[item.name] : item.name }}</a>
      </li>
    </router-link>

  </CBreadcrumb>
</template>

<script>
import {onMounted, ref} from 'vue'
import router from '@/router'

export default {
  name: 'AppBreadcrumb',
  setup() {
    const breadcrumbs = ref()

    const getBreadcrumbs = () => {
      return router.currentRoute.value.matched.map((route) => {
        return {
          active: route.path === router.currentRoute.value.fullPath,
          name: route.name,
          path: `${router.options.history.base}${route.path}`,
        }
      })
    }

    router.afterEach(() => {
      breadcrumbs.value = getBreadcrumbs()
    })

    onMounted(() => {
      breadcrumbs.value = getBreadcrumbs()
    })

    return {
      breadcrumbs,
    }
  },
}
</script>
