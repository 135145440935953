import {TranslatableItem, t} from "./translation";
import {locales} from "@/app/trans/js/locales";

export function translate(object) {
  const defaultObj = {
    currentLocale: () => {
      const loca = locales.value
      if (loca)
        return loca[0]
      return null
    },
    setLocale: (code) => {
      locales.value = code
      localStorage.setItem('user-locale', code)
      location.reload()
    }
  }

  Object.assign(object, defaultObj)

  return new Proxy(object, translatorProxyHandlers);
}

const VUE_INTERNAL_PREFIX = "__";
const translatorProxyHandlers = {
  get(target, key) {
    const res = Reflect.get(target, key);
    if (typeof key === "string") {
      if (key === "_raw") {
        return target;
      }
      if (typeof res === "object") {
        if (res instanceof TranslatableItem) {
          return t(res);
        } else {
          return translate(res);
        }
      } else {
        if (res === undefined && !key.startsWith(VUE_INTERNAL_PREFIX) && process.env.NODE_ENV !== 'production') {
          console.warn(`Translation key '${key}' not found!`);
        }
        return res;
      }
    } else {
      return res;
    }
  },
  set() {
    return false;
  },
  defineProperty() {
    return false;
  },
  deleteProperty() {
    return false;
  },
};
