import { l, t } from "../translation";
import { getLocales } from "../locales";
export const ucFirstItem = l({
    fallback: (s) => {
        return s.charAt(0).toLocaleUpperCase(getLocales()) + s.slice(1);
    },
});
export function ucFirst(s) {
    return t(ucFirstItem)(s);
}
