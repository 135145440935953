import {BehaviorSubject, debounceTime, distinctUntilChanged, fromEvent} from "rxjs";
import {HeaderTrigger} from "@/app/unit-format";

export default {
  install(app, options) {
    app.config.globalProperties.$resizeEvent = fromEvent(window, 'resize').pipe(
      debounceTime(250),
      distinctUntilChanged()
    )

    app.config.globalProperties.$headerTrigger = new BehaviorSubject(new HeaderTrigger())
  }
}
