import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'
import 'moment/locale/ja'
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css'

moment.locale('ja')

import AppIcon from '@/components/AppIcon'
import {$t} from "@/app/translate";
import {Tooltip} from '@/app/plugins/tooltip'
import UI from '@/app/plugins/ui'

import Axios from '@/app/plugins/axios'
import Alert from '@/app/plugins/alert'
import GlobalEvent from '@/app/plugins/global-event.js'
import SubmitBtn from '@/components/form/SubmitBtn';
import Progressbar from "@/app/plugins/progressbar";
import {CONSTANTS} from "@/app/config";
import initFilter from "@/filter";

const app = createApp(App)
app.use(store)
app.use(router)
app.use(UI)

app.use(Axios)
app.use(Alert)
app.use(GlobalEvent)
app.use(Progressbar)

initFilter(app)

app.component('AppIcon', AppIcon)
app.component('VTooltip', Tooltip)
app.component('SubmitBtn', SubmitBtn)

app.component('Datepicker', Datepicker);
app.config.globalProperties.$t = $t
app.config.globalProperties.$C = CONSTANTS

app.mount('#app')

window.moment = moment
