import {h, resolveComponent} from 'vue'
import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'
import {checkLogin} from "@/app/guards/admin.guard";
// import ChatManager from "@/views/ChatManager";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    beforeEnter: [checkLogin],
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('@/views/Dashboard.vue'),
      },
      {
        path: '/user',
        name: 'user',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/user/list',
        children: [
          {
            path: '/user/list',
            name: 'user.list',
            component: () => import('@/views/user/ListUser.vue'),
          },
          {
            path: '/user/report',
            name: 'user.report',
            component: () => import('@/views/user/RepostUser.vue')
          },
          {
            path: '/user/add',
            name: 'user.add',
            component: () => import('@/views/user/add-user/AddUser.vue'),
          },
          {
            path: '/user/update/:userId',
            name: 'user.edit',
            component: () => import('@/views/user/add-user/AddUser.vue'),
          },
          {
            path: '/user/verify',
            name: 'user.verify',
            component: () => import('@/views/user/VerifyId.vue'),
          },
          {
            path: '/user/password',
            name: 'user.password',
            component: () => import('@/views/user/ChangePassword.vue'),
          },
        ]
      },
      {
        path: '/category',
        name: 'category',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/category/index',
        children: [
          {
            path: '/category/index',
            name: 'category.index',
            component: () => import('@/views/category/Index'),
          }
        ]
      },
      {
        path: '/product',
        name: 'product',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/product/list',
        children: [
          {
            path: '/product/list',
            name: 'product.list',
            component: () => import('@/views/product/ListProduct'),
          },
          {
            path: '/product/pending',
            name: 'product.pending.list',
            component: () => import('@/views/product/ListWaitApprove'),
          },
          {
            path: '/product/detail/:id',
            name: 'product.detail',
            component: () => import('@/views/product/ProductDetail'),
          }
        ]
      },
      {
        path: '/order',
        name: 'order',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/order/list',
        children: [
          {
            path: '/order/list',
            name: 'order.list',
            component: () => import('@/views/order/ListOrder'),
          },
          {
            path: '/order/:id',
            name: 'order.detail',
            component: () => import('@/views/order/DetailOrder'),
          },
          {
            path: '/order/report',
            name: 'order.report',
            component: () => import('@/views/order/ListOrderAccused'),
          },
        ]
      },
      {
        path: '/revenue',
        name: 'revenue',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/revenue/list',
        children: [
          {
            path: '/revenue/dashboard',
            name: 'revenue.dashboard',
            component: () => import('@/views/revenue/RevenueDashboard'),
          },
          {
            path: '/revenue/list',
            name: 'revenue.list',
            component: () => import('@/views/revenue/RevenueList'),
          },
        ],
      },
      {
        path: '/system/notification',
        name: 'notification',
        component: () => import('@/views/notification/Notification')
      },
      {
        path: '/cog',
        name: 'cog',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/cog/area',
        children: [
          {
            path: '/cog/area',
            name: 'cog.area',
            component: () => import('@/views/area/Index'),
          },
          /*{
            path: '/cog/chat-mirror',
            name: 'cog.chat',
            component: () => import('@/views/config/ChatMirror'),
          },*/
          {
            path: '/cog/default',
            name: 'cog.default',
            component: () => import('@/views/config/DefaultConfig'),
          },
        ]
      },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    beforeEnter: [checkLogin],
    component: () => import('@/views/pages/Login.vue'),
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    beforeEnter: [checkLogin],
    component: () => import('@/views/pages/forgot-password.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(), //createWebHashHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return {top: 0}
  },
})

export default router
