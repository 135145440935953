<template>
  <div>
    <VueProgressBar></VueProgressBar>
    <AppSidebar/>
    <div class="wrapper d-flex flex-column min-vh-100">
      <AppHeader/>
      <div class="body flex-grow-1 px-3">
        <div class="container-fluid" style="position: relative">
          <router-view/>
          <div class="app-loading" v-if="showLoading">
            <CSpinner color="info" variant="grow"/>
          </div>
        </div>
      </div>
      <AppFooter/>
    </div>
  </div>
</template>
<script>
import {CContainer} from '@coreui/vue'
import AppFooter from '@/components/AppFooter.vue'
import AppHeader from '@/components/AppHeader.vue'
import AppSidebar from '@/components/AppSidebar.vue'
import {useStore} from 'vuex'
import {computed} from "vue";

export default {
  name: 'DefaultLayout',
  components: {
    AppFooter,
    AppHeader,
    AppSidebar,
    CContainer,
  },
  mounted() {
    this.$progress.finish();
  },
  created() {
    this.$progress.start();

    this.$router.beforeEach((to, from, next) => {
      this.$progress.start();
      next();
    });

    this.$router.afterEach((to, from) => {
      this.$progress.finish();
    });
  },
  setup() {
    const store = useStore()
    return {
      showLoading: computed(() => store.state.pageLoading),
    }
  }
}
</script>

<style>
.app-loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background: #ffffffbd;
  pointer-events: none;
  display: flex;
  /*align-items: center;*/
  justify-content: center;
}
</style>
