<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CAvatar :src="avatar" size="md"/>
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        アカウント
      </CDropdownHeader>
      <router-link to="/user/password" class="dropdown-item">
        <app-icon name="icon-regular-key" customClassName="icon"></app-icon>
        パスワード再設定
      </router-link>
      <CDropdownDivider/>
      <CDropdownItem @click="userLogout()">
        <app-icon name="icon-regular-sign-out-alt" customClassName="icon"></app-icon>
        ログアウト
      </CDropdownItem>
      <template v-if="appModel !== 'production'">
        <CDropdownItem @click="setLocale('vi-VN')">
          <app-icon name="icon-regular-language" customClassName="icon"></app-icon>
          Vietnam (dev only)
        </CDropdownItem>
        <CDropdownItem @click="setLocale('ja-JP')">
          <app-icon name="icon-regular-language" customClassName="icon"></app-icon>
          Japan (dev only)
        </CDropdownItem>
      </template>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import avatar from '@/assets/images/avatars/11.png'

export default {
  name: 'AppHeaderDropdownAccnt',
  data() {
    return {
      appModel: process.env.NODE_ENV
    }
  },
  setup() {
    return {
      avatar: avatar,
      itemsCount: 42,
    }
  },
  methods: {
    userLogout() {
      this.$swal.showConfirmOpt({
        icon: 'question',
        title: 'ログアウト',
        text: "ログアウトしますか",
        showConfirmButton: true,
        cancelButtonText: "キャンセル",
        confirmButtonText: "ログアウト",
        showCancelButton: true
      }).then(res => {
        if (res.isConfirmed) {
          this.$store.dispatch('logout')
          location.replace('/login')
        }
      })
    },
    setLocale(str) {
      this.$t.setLocale(str)
      // this.$store.dispatch('setAppLocale', str)
    }
  }
}
</script>
