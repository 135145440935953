import { computed, ref } from "vue";
export const locales = ref([]);
export const fallbackLocales = ref(["en"]);
const forceLocales = ref();
const orderedLocales = computed(() => {
    return locales.value.concat(getNavigatorLanguages()).concat(fallbackLocales.value);
});
export function getNavigatorLanguages() {
    return typeof navigator !== "undefined" ? navigator.languages : [];
}
export function getLocales() {
    var _a;
    return (_a = forceLocales.value) !== null && _a !== void 0 ? _a : orderedLocales.value;
}
export function getPrimaryLocale() {
    const locales = getLocales();
    return locales.length ? locales[0] : "fallback";
}
/**
 * Can be used to override the locales temporarily.
 * Example usage: `const c = withLocale(["en-GB"], () => local(LocalizationSettings.currency))` ('GBP')
 */
export function withLocales(locales, callback) {
    forceLocales.value = locales;
    let result;
    try {
        result = callback();
    }
    finally {
        forceLocales.value = undefined;
    }
    return result;
}
