import { translate } from "../translator";
import { l, t } from "../translation";
import { getLocales } from "../locales";
export const dateTimeFormats = translate({
    full: l({ fallback: { dateStyle: "full" } }),
    long: l({ fallback: { dateStyle: "long" } }),
    medium: l({ fallback: { dateStyle: "medium" } }),
    short: l({ fallback: { dateStyle: "short" } }),
});
function getDateTimeFormat(mode, extraOptions = {}) {
    let options = dateTimeFormats[mode] || {};
    if (extraOptions) {
        options = Object.assign({}, options, extraOptions);
    }
    return Intl.DateTimeFormat(getLocales(), options);
}
export const datetimePartsItem = l({
    fallback: (date, mode, extraOptions = {}) => {
        return getDateTimeFormat(mode, extraOptions).formatToParts(date);
    },
});
export function datetimeParts(date, mode, extraOptions = {}) {
    return t(datetimePartsItem)(date, mode, extraOptions);
}
export function datetime(date, mode, extraOptions = {}) {
    return t(datetimePartsItem)(date, mode, extraOptions)
        .map((item) => item.value)
        .join("");
}
