import vcplaceholder, {
  CAccordion,
  CAccordionBody,
  CAccordionButton,
  CAccordionCollapse,
  CAccordionHeader,
  CAccordionItem,
  CAccordionPlugin,
  CAlert,
  CAlertHeading,
  CAlertLink,
  CAlertPlugin,
  CAvatar,
  CAvatarPlugin,
  CBackdrop,
  CBackdropPlugin,
  CBadge,
  CBadgePlugin,
  CBreadcrumb,
  CBreadcrumbItem,
  CBreadcrumbPlugin,
  CButton,
  CButtonGroup,
  CButtonGroupPlugin,
  CButtonPlugin,
  CButtonToolbar,
  CCallout,
  CCalloutPlugin,
  CCard,
  CCardBody,
  CCardFooter,
  CCardGroup,
  CCardHeader,
  CCardImage,
  CCardImageOverlay,
  CCardLink,
  CCardPlugin,
  CCardSubtitle,
  CCardText,
  CCardTitle,
  CCarousel,
  CCarouselCaption,
  CCarouselItem,
  CCarouselPlugin,
  CCloseButton,
  CCloseButtonPlugin,
  CCol,
  CCollapse,
  CCollapsePlugin,
  CContainer,
  CFooter,
  CFooterPlugin,
  CForm,
  CFormCheck,
  CFormFeedback,
  CFormFloating,
  CFormInput,
  CFormLabel,
  CFormPlugin,
  CFormRange,
  CFormSelect,
  CFormSwitch,
  CFormText,
  CFormTextarea,
  CGridPlugin,
  CHeader,
  CHeaderBrand, CHeaderDivider,
  CHeaderNav,
  CHeaderPlugin,
  CHeaderText,
  CHeaderToggler,
  CImage,
  CImagePlugin,
  CInputGroup,
  CInputGroupText,
  CListGroup,
  CListGroupItem,
  CListGroupPlugin,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalPlugin,
  CModalTitle,
  COffcanvas,
  COffcanvasBody,
  COffcanvasHeader,
  COffcanvasPlugin,
  COffcanvasTitle,
  CPagination,
  CPaginationItem,
  CPaginationPlugin,
  CPlaceholder,
  CPlaceholderPlugin,
  CPopover,
  CPopoverPlugin,
  CProgress,
  CProgressBar,
  CProgressPlugin,
  CRow,
  CSidebar,
  CSidebarBrand,
  CSidebarFooter,
  CSidebarHeader,
  CSidebarNav,
  CSidebarPlugin,
  CSidebarToggler,
  CSpinner,
  CSpinnerPlugin,
  CTabContent,
  CTable,
  CTableBody,
  CTableCaption,
  CTableDataCell,
  CTableFoot,
  CTableHead,
  CTableHeaderCell,
  CTablePlugin,
  CTableRow,
  CTabPane,
  CTabsPlugin,
  CToast,
  CToastBody,
  CToastClose,
  CToaster,
  CToastHeader,
  CToastPlugin,
  CTooltip,
  CTooltipPlugin,
  CWidgetsStatsPlugin,
  CWidgetStatsA,
  CWidgetStatsB,
  CWidgetStatsC,
  CWidgetStatsD,
  CWidgetStatsE,
  CWidgetStatsF,
} from '@coreui/vue'

import vcpopover from "@coreui/vue";
import vctooltip from "@coreui/vue";
import {
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem, CDropdownMenu,
  CDropdownPlugin, CDropdownToggle,
} from "@/app/plugins/ui/hack/dropdown";

import {CLink, CCLinkPlugin} from '@/app/plugins/ui/hack/link'

import {
  CNav,
  CNavGroup,
  CNavGroupItems,
  CNavItem,
  CNavLink,
  CNavPlugin,
  CNavTitle,
} from "@/app/plugins/ui/hack/nav"

import {
  CNavbar,
  CNavbarBrand,
  CNavbarNav,
  CNavbarPlugin,
  CNavbarText,
  CNavbarToggler,
} from "@/app/plugins/ui/hack/navbar"

const Components = Object.freeze({
  __proto__: null,
  CAccordionPlugin: CAccordionPlugin,
  CAccordion: CAccordion,
  CAccordionBody: CAccordionBody,
  CAccordionButton: CAccordionButton,
  CAccordionCollapse: CAccordionCollapse,
  CAccordionHeader: CAccordionHeader,
  CAccordionItem: CAccordionItem,
  CAlertPlugin: CAlertPlugin,
  CAlert: CAlert,
  CAlertHeading: CAlertHeading,
  CAlertLink: CAlertLink,
  CAvatarPlugin: CAvatarPlugin,
  CAvatar: CAvatar,
  CBackdropPlugin: CBackdropPlugin,
  CBackdrop: CBackdrop,
  CBadge: CBadge,
  CBadgePlugin: CBadgePlugin,
  CBreadcrumbPlugin: CBreadcrumbPlugin,
  CBreadcrumb: CBreadcrumb,
  CBreadcrumbItem: CBreadcrumbItem,
  CButtonPlugin: CButtonPlugin,
  CButton: CButton,
  CButtonGroupPlugin: CButtonGroupPlugin,
  CButtonToolbar: CButtonToolbar,
  CButtonGroup: CButtonGroup,
  CCalloutPlugin: CCalloutPlugin,
  CCallout: CCallout,
  CCardPlugin: CCardPlugin,
  CCard: CCard,
  CCardBody: CCardBody,
  CCardFooter: CCardFooter,
  CCardGroup: CCardGroup,
  CCardHeader: CCardHeader,
  CCardImage: CCardImage,
  CCardImageOverlay: CCardImageOverlay,
  CCardLink: CCardLink,
  CCardSubtitle: CCardSubtitle,
  CCardText: CCardText,
  CCardTitle: CCardTitle,
  CCarouselPlugin: CCarouselPlugin,
  CCarousel: CCarousel,
  CCarouselCaption: CCarouselCaption,
  CCarouselItem: CCarouselItem,
  CCloseButtonPlugin: CCloseButtonPlugin,
  CCloseButton: CCloseButton,
  CCollapsePlugin: CCollapsePlugin,
  CCollapse: CCollapse,
  CDropdownPlugin: CDropdownPlugin,
  CDropdown: CDropdown,
  CDropdownItem: CDropdownItem,
  CDropdownHeader: CDropdownHeader,
  CDropdownDivider: CDropdownDivider,
  CDropdownMenu: CDropdownMenu,
  CDropdownToggle: CDropdownToggle,
  CFooterPlugin: CFooterPlugin,
  CFooter: CFooter,
  CFormPlugin: CFormPlugin,
  CForm: CForm,
  CFormCheck: CFormCheck,
  CFormFeedback: CFormFeedback,
  CFormFloating: CFormFloating,
  CFormInput: CFormInput,
  CFormLabel: CFormLabel,
  CFormRange: CFormRange,
  CFormSelect: CFormSelect,
  CFormSwitch: CFormSwitch,
  CFormText: CFormText,
  CFormTextarea: CFormTextarea,
  CInputGroup: CInputGroup,
  CInputGroupText: CInputGroupText,
  CGridPlugin: CGridPlugin,
  CCol: CCol,
  CContainer: CContainer,
  CRow: CRow,
  CHeaderPlugin: CHeaderPlugin,
  CHeader: CHeader,
  CHeaderBrand: CHeaderBrand,
  CHeaderDivider: CHeaderDivider,
  CHeaderNav: CHeaderNav,
  CHeaderText: CHeaderText,
  CHeaderToggler: CHeaderToggler,
  CImagePlugin: CImagePlugin,
  CImage: CImage,
  CCLinkPlugin: CCLinkPlugin,
  CLink: CLink,
  CListGroupPlugin: CListGroupPlugin,
  CListGroup: CListGroup,
  CListGroupItem: CListGroupItem,
  CModalPlugin: CModalPlugin,
  CModal: CModal,
  CModalBody: CModalBody,
  CModalFooter: CModalFooter,
  CModalHeader: CModalHeader,
  CModalTitle: CModalTitle,
  CNavPlugin: CNavPlugin,
  CNav: CNav,
  CNavGroup: CNavGroup,
  CNavGroupItems: CNavGroupItems,
  CNavItem: CNavItem,
  CNavLink: CNavLink,
  CNavTitle: CNavTitle,
  CNavbarPlugin: CNavbarPlugin,
  CNavbar: CNavbar,
  CNavbarBrand: CNavbarBrand,
  CNavbarNav: CNavbarNav,
  CNavbarText: CNavbarText,
  CNavbarToggler: CNavbarToggler,
  COffcanvasPlugin: COffcanvasPlugin,
  COffcanvas: COffcanvas,
  COffcanvasBody: COffcanvasBody,
  COffcanvasHeader: COffcanvasHeader,
  COffcanvasTitle: COffcanvasTitle,
  CPaginationPlugin: CPaginationPlugin,
  CPagination: CPagination,
  CPaginationItem: CPaginationItem,
  CPlaceholderPlugin: CPlaceholderPlugin,
  CPlaceholder: CPlaceholder,
  CProgressPlugin: CProgressPlugin,
  CProgress: CProgress,
  CProgressBar: CProgressBar,
  CPopoverPlugin: CPopoverPlugin,
  CPopover: CPopover,
  CSidebarPlugin: CSidebarPlugin,
  CSidebar: CSidebar,
  CSidebarBrand: CSidebarBrand,
  CSidebarFooter: CSidebarFooter,
  CSidebarHeader: CSidebarHeader,
  CSidebarNav: CSidebarNav,
  CSidebarToggler: CSidebarToggler,
  CSpinnerPlugin: CSpinnerPlugin,
  CSpinner: CSpinner,
  CTablePlugin: CTablePlugin,
  CTable: CTable,
  CTableBody: CTableBody,
  CTableCaption: CTableCaption,
  CTableDataCell: CTableDataCell,
  CTableFoot: CTableFoot,
  CTableHead: CTableHead,
  CTableHeaderCell: CTableHeaderCell,
  CTableRow: CTableRow,
  CTabsPlugin: CTabsPlugin,
  CTabContent: CTabContent,
  CTabPane: CTabPane,
  CToastPlugin: CToastPlugin,
  CToast: CToast,
  CToastBody: CToastBody,
  CToastClose: CToastClose,
  CToaster: CToaster,
  CToastHeader: CToastHeader,
  CTooltipPlugin: CTooltipPlugin,
  CTooltip: CTooltip,
  CWidgetsStatsPlugin: CWidgetsStatsPlugin,
  CWidgetStatsA: CWidgetStatsA,
  CWidgetStatsB: CWidgetStatsB,
  CWidgetStatsC: CWidgetStatsC,
  CWidgetStatsD: CWidgetStatsD,
  CWidgetStatsE: CWidgetStatsE,
  CWidgetStatsF: CWidgetStatsF,
});

const removeKeysFromObject = (object, keys) => {
  return Object.entries(object).reduce((obj, [key, value]) => {
    if (!keys.includes(key) && !keys.includes(value.name)) {
      obj[key] = value;
    }
    return obj;
  }, {});
};

export default {
  install: (app, options) => {
    let pluginComponents = Components;
    // let pluginDirectives = Directives
    const toRemove = options && options.remove ? options.remove : null;
    if (toRemove && Array.isArray(toRemove)) {
      pluginComponents = removeKeysFromObject(Components, toRemove);
      // pluginDirectives = removeKeysFromObject(Directives, toRemove)
    }
    for (const plugin in pluginComponents) {
      app.component(plugin, Components[plugin]);
    }
// for (const directive in pluginDirectives) {
//   app.directive(directive, Directives[directive])
// }
    app.directive('c-placeholder', vcplaceholder);
    app.directive('c-popover', vcpopover);
    app.directive('c-tooltip', vctooltip);
  },
}
