<template>
  <router-view/>
</template>

<script>
// import MasterService from "@/app/service/master.service";


export default {
  mounted() {

    // get global config
    /*MasterService.getConst().http.then(res => {
      window.globalConst = res.data
      this.$globalConst = res.data
    })*/
  }
}
</script>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
</style>
