import moment from 'moment';

export default function initFilter(app) {

  app.config.globalProperties.$filters = {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format('YYYY/MM/DD hh:mm')
      }
    }
  }
}
