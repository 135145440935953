import Store from '@/store'

async function checkAndLogin(next, nextUrl = '', to = null) {
  try {
    await Store.dispatch('getUserInfo')
    if (Store.state.currentUser && Store.state.currentUser._id) {
      nextUrl ? next(nextUrl) : next()
    } else {
      await Store.dispatch('setCurrentUser', {
        token: '',
        user: null,
      })

      if (to && to.name !== 'Login')
        next({path: '/login'})
    }
  } catch (e) {
    await Store.dispatch('setCurrentUser', {
      token: '',
      user: null,
    })

    localStorage.removeItem('userToken')

    location.replace('/login')
  }
}

export async function checkLogin(to, from, next) {
  const token = localStorage.getItem('userToken')
  const publicPath = ['/login', '/forgot-password']

  if (publicPath.indexOf(to.path) === -1) {
    if (token) {
      await checkAndLogin(next,'', to)
    } else {
      console.log(to)
      next({path: '/login'})
    }
  } else if (token) {
    console.log(from)
    await checkAndLogin(next, '/dashboard', to)
  } else {
    next()
  }
}
