import {locales, translate} from "@/app/trans/js";

const translateObj = {}
//only on build
const TranslateContext = require.context('@/resources/lang', false, /-translate.js$/i);
TranslateContext.keys().forEach((componentFilePath) => {
  const obj = {...TranslateContext(componentFilePath)}
  Object.keys(obj).map(n => {
    translateObj[n] = obj[n]
  })
});

const userLocale = localStorage.getItem('user-locale')

locales.value = [userLocale ? userLocale : process.env.VUE_APP_APP_LOCALE];

export const $t = translate(translateObj);
