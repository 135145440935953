import Swal from "sweetalert2";

class Alert {
  constructor() {
    this.fire = Swal.fire
  }

  showError(message, title = 'エラー') {
    return Swal.fire({
      icon: 'error',
      title: title,
      allowOutsideClick: false,
      text: message,
      confirmButtonText: '閉じる'
    })
  }

  showSuccess(message, title = '完了') {
    return Swal.fire({
      icon: 'success',
      title: title,
      allowOutsideClick: false,
      text: message,
      confirmButtonText: ' 閉じる'
    })
  }

  showInfo(message, title = '通知') {
    return Swal.fire({
      icon: 'info',
      title: title,
      // showCloseButton: true,
      allowOutsideClick: false,
      text: message
    })
  }

  showWarning(message, title = '警報') {
    return Swal.fire({
      icon: 'warning',
      title: title,
      // showCloseButton: true,
      allowOutsideClick: false,
      text: message
    })
  }

  showConfirm(message, title = '確認') {
    return Swal.fire({
      title: title,
      text: message,
      icon: 'question',
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#b09e9e',
      confirmButtonText: 'はい',
      cancelButtonText: 'いいえ'
    })
  }

  showConfirmOpt(opt) {
    return Swal.fire(opt)
  }

  showConfirmInputText(title, label) {
    return Swal.fire({
      title: title,
      input: 'text',
      inputLabel: label,
      inputValue: '',
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonText: '次へ',
      cancelButtonText: 'キャンセル',
      inputValidator: (value) => {
        if (!value) {
          return 'あなたは何かを書く必要があります！'
        }
      }
    })
  }
}

export default {
  install(app, options) {
    app.config.globalProperties.$swal = new Alert();
  }
}
