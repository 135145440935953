export default [
  // {
  //   component: 'CNavItem',
  //   name: '概要',
  //   to: '/dashboard',
  //   icon: 'icon-solid-tachometer-alt',
  //   badge: {
  //     color: 'primary',
  //     text: 'NEW',
  //   },
  // },
  {
    component: 'CNavItem',
    name: 'ユーザー管理',
    to: '/user',
    icon: 'icon-regular-users',
    items: [
      {
        component: 'CNavItem',
        name: 'アカウント一覧',
        to: '/user/list',
      },
      {
        component: 'CNavItem',
        name: 'アカウント追加',
        to: '/user/add',
      },
      // {
      //   component: 'CNavItem',
      //   name: 'Đợi phê duyệt',
      //   to: '/user/check-id',
      // },
      {
        component: 'CNavItem',
        name: 'バックリスト',
        to: '/user/report',
      }
    ]
  },
  {
    component: 'CNavItem',
    name: '投稿管理',
    to: '/product',
    icon: 'icon-solid-store',
    items: [
      {
        component: 'CNavItem',
        name: 'カテゴリー管理',
        to: '/category/index',
      },
      {
        component: 'CNavItem',
        name: '投稿一覧',
        to: '/product/list',
      },
      {
        component: 'CNavItem',
        name: '承認待ちの投稿',
        to: '/product/pending',
      }
    ]
  },
  {
    component: 'CNavItem',
    name: '注文管理',
    to: '/order',
    icon: 'icon-regular-shopping-bag',
    items: [
      {
        component: 'CNavItem',
        name: '注文一覧',
        to: '/order/list',
      },
      // {
      //   component: 'CNavItem',
      //   name: 'Đơn bị báo cáo',
      //   to: '/order/report',
      // },
    ]
  },
  {
    component: 'CNavItem',
    name: '売上管理',
    to: '/revenue',
    icon: 'icon-regular-money-check-alt',
    items: [
      {
        component: 'CNavItem',
        name: '一般',
        to: '/revenue/dashboard',
      },
      {
        component: 'CNavItem',
        name: '決済一覧',
        to: '/revenue/list',
      },
    ]
  },
  {
    component: 'CNavItem',
    name: 'お知らせ',
    to: '/system/notification',
    icon: 'icon-regular-bell',
  },
  // {
  //   component: 'CNavItem',
  //   name: 'Hoạt động',
  //   to: '/system',
  //   icon: 'icon-regular-tasks-alt',
  //   items: [
  //     // {
  //     //   component: 'CNavItem',
  //     //   name: 'Liên hệ của người dùng',
  //     //   to: '/system/contact'
  //     // },
  //     {
  //       component: 'CNavItem',
  //       name: 'Thông báo hệ thống',
  //       to: '/system/notification'
  //     }
  //   ]
  // },
  {
    component: 'CNavItem',
    name: '設定',
    to: '/cog',
    icon: 'icon-regular-cogs',
    items: [
      {
        component: 'CNavItem',
        name: '地域設定',
        to: '/cog/area'
      },
      {
        component: 'CNavItem',
        name: '一般設定',
        to: '/cog/default'
      },
      // {
      //   component: 'CNavItem',
      //   name: 'チャットミラー',
      //   to: '/cog/chat-mirror'
      // },
    ]
  },
]
