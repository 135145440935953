import {l} from "@/app/trans/js";

export const listOrder = {
  user: l({
    vi: "Người đặt",
    en: "User",
    ja: "Nguời đặt"
  }),
  seller: l({
    vi: "Người bán",
    en: "Seller",
    ja: "Nguời bán"
  }),
  title: l({
    vi: "Tên post",
    en: "Name post",
    ja: "Name post"
  }),
  phone: l({
    vi: "Điện thoại",
    en: "Phone",
    ja: "Phone"
  }),
  address: l({
    vi: "Địa chỉ giao hàng",
    en: "Address",
    ja: "Address"
  }),
  status: l({
    vi: "Trạng thái",
    en: "Status",
    ja: "Status"
  }),
  reasonCancel: l({
    vi: "Lý do",
    en: "Reason",
    ja: "Lý do"
  }),
  timeAccept: l({
    vi: "Ngày Xác nhận",
    en: "AcceptedAt",
    ja: "Ngày Xác nhận"
  }),
  timeDelivery: l({
    vi: "Ngày Vận chuyển",
    en: "deliveryAt",
    ja: "Ngày vận chuyển"
  }),
  timeDone: l({
    vi: "Ngày Hoàn thành",
    en: "deliveryAt",
    ja: "Ngày hoàn thành"
  }),
  timeCancel: l({
    vi: "Ngày huỷ",
    en: "cancelAt",
    ja: "Ngày hủy"
  }),
  timePayment: l({
    vi: "Ngày thanh toán",
    en: "cancelAt",
    ja: "Ngày thanh toán"
  }),
  createdAt: l({
    vi: "Ngày tạo đơn hàng",
    en: "CreatedAt",
    ja: "Ngày tạo"
  }),
  note: l({
    vi: 'Ghi chú',
    en: 'Note',
    ja: 'Note'
  }),
  paymentMethod: l({
    vi: 'Phương thức thanh toán',
    en: 'Payment Method',
    ja: 'Payment Method'
  }),
  paymentStatus: l({
    vi: 'Trạng thái thanh toán',
    en: 'Payment Status',
    ja: 'Trạng thái thanh toán'
  }),
  orderStatus: l({
    vi: 'Trạng thái đơn hàng',
    en: 'Order Status',
    ja: 'Trạng thái đơn hàng'
  }),
  cardId: l({
    vi: 'Xác thực thông tin cá nhân',
    en: 'Verify personal information',
    ja: '個人情報を確認する'
  }),
  confirmId: l({
    vi: 'Đồng ý',
    en: 'Ok',
    ja: '同意'
  }),
  rejectId: l({
    vi: 'Từ chối',
    en: 'Reject',
    ja: 'ごみ'
  }),
  phoneVerify0: l({
    vi: 'Chưa duyệt',
    en: 'Not approved yet',
    ja: 'まだ承認されていません'
  }),
  phoneVerify1: l({
    vi: 'Đã duyệt',
    en: 'Approved',
    ja: '承認済み'
  }),
  phoneVerify2: l({
    vi: 'Bị từ chối',
    en: 'Denied',
    ja: '拒否された'
  }),
  tax: l({
    vi: 'Thuế',
    en: 'Tax',
    ja: 'Thuế'
  }),
  fee: l({
    vi: 'Phí',
    en: 'Fee',
    ja: 'Phí'
  }),
  discount: l({
    vi: 'Chiết khấu',
    en: 'Discount',
    ja: 'Chiết khấu'
  }),
  admin_collect: l({
    vi: 'Admin nhận được',
    en: 'Admin collect',
    ja: 'Admin nhận được'
  }),
  seller_collect: l({
    vi: 'Người bán nhận được',
    en: 'Seller collect',
    ja: 'Người bán nhận được'
  }),
  price: l({
    vi: 'Giá',
    en: 'Price',
    ja: 'Giá'
  })
}

export const addUser = {
  title: l({
    vi: 'Tạo tài khoản mới',
    en: 'Create a new account',
    ja: '新しいアカウントを作成する'
  }),
  titleUpdate: l({
    vi: 'Cập nhật thông tin tài khoản',
    en: 'Update account information',
    ja: 'アカウント情報を更新する'
  }),
  titleDefault: l({
    vi: 'Thông tin bắt buộc',
    en: 'Required Information',
    ja: '必要な情報'
  }),
  titleInfo: l({
    vi: 'Thông tin thêm',
    en: 'More information',
    ja: '詳しくは'
  }),
  name: l({
    vi: 'Họ tên',
    en: 'Full name',
    ja: 'フルネーム'
  }),
  nameKana: l({
    vi: 'Họ tên kana',
    en: 'Name kana',
    ja: '名前かな'
  }),
  email: l({
    vi: 'Địa chỉ email',
    en: 'Email address',
    ja: '電子メールアドレス'
  }),
  password: l({
    vi: 'Mật khẩu',
    en: 'Password',
    ja: 'パスワード'
  }),
  phone: l({
    vi: 'Điện thoại',
    en: 'Phone',
    ja: '電話'
  }),
  address: l({
    vi: 'Địa chỉ',
    en: 'Address',
    ja: '住所'
  }),
  status: l({
    vi: 'Trạng thái',
    en: 'Status',
    ja: '状態'
  }),
  status1: l({
    vi: 'Đang hoạt động',
    en: 'Status',
    ja: '状態'
  }),
  status2: l({
    vi: 'Ngừng hoạt động',
    en: 'Status',
    ja: '状態'
  }),
  status3: l({
    vi: 'Đang khóa',
    en: 'Status',
    ja: '状態'
  }),
  role: l({
    vi: 'Loại tài khoản',
    en: 'Account Type',
    ja: '口座の種類'
  }),
  role1: l({
    vi: 'Tài khoản thường',
    en: 'Regular account',
    ja: '通常のアカウント'
  }),
  role2: l({
    vi: 'Tài khoản admin',
    en: 'Admin account',
    ja: '管理者アカウント'
  }),
  roleTitle: l({
    vi: 'Quyền hạn của tài khoản này',
    en: 'Permissions of this account',
    ja: 'このアカウントの権限'
  }),
  validate: {
    name: {
      required: l({
        vi: 'Bắt buộc nhập họ tên tài khoản',
        en: 'Full name is required',
        ja: 'ユーザー名が必要です'
      }),
      maxLength: l({
        vi: 'Tên tài khoản không được vượt quá 200 ký tự',
        en: 'Username cannot exceed 200 characters',
        ja: 'ユーザー名は200文字を超えることはできません'
      })
    },
    email: {
      required: l({
        vi: 'Bắt buộc nhập email',
        en: 'Required to enter email',
        ja: 'メールを入力する必要があります'
      }),
      email: l({
        vi: 'Địa chỉ email không hợp lệ',
        en: 'Email address is not valid',
        ja: 'メールアドレスが無効です'
      })
    },
    password: {
      required: l({
        vi: 'Bắt buộc nhập mật khẩu',
        en: 'Password required',
        ja: 'パスワードが必要',
      }),
      minLength: l({
        vi: 'Mật khẩu phải từ 6 ký tự trở lên',
        en: 'Password must be 6 or more characters',
        ja: 'パスワードは6文字以上である必要があります',
      })
    }
  }
}
