import { getLocales } from "./locales";
import { shallowReactive } from "vue";
/**
 * Returns the item's translation for the current locale.
 */
export function t(item, locales = getLocales()) {
    for (let i = 0; i < locales.length; i++) {
        const result = getTranslation(item, locales[i]);
        if (result)
            return result;
    }
    if (item.locales.fallback) {
        return item.locales.fallback;
    }
    else {
        return getFirstLocaleValue(item);
    }
}
function getTranslation(item, locale) {
    const match = item.locales[locale];
    if (match !== undefined) {
        // Quick path: perfect match.
        return match;
    }
    else {
        // Part-by-part lookup.
        let index;
        let partialLocale = locale;
        while ((index = partialLocale.lastIndexOf("-")) > 0) {
            partialLocale = partialLocale.substr(0, index);
            const partialMatch = item.locales[partialLocale];
            if (partialMatch !== undefined)
                return partialMatch;
        }
        return undefined;
    }
}
function getFirstLocaleValue(item) {
    const firstKey = Object.keys(item.locales)[0];
    return item.locales[firstKey];
}
export class TranslatableItem {
    constructor(locales) {
        this.locales = shallowReactive(locales);
    }
    patch(other) {
        Object.assign(this.locales, other.locales);
    }
    get __v_skip() {
        // Skip reactivity for item, because we don't want the values to become reactive.
        return true;
    }
}
/**
 * Creates an Item for the specified ItemLocales.
 * Notice that this only ensures that the typescript type is correct.
 */
export function l(locales) {
    return new TranslatableItem(locales);
}
