<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <i class="app-icon icon-regular-bars"/>
      </CHeaderToggler>
      <CHeaderBrand class="mx-auto d-lg-none" to="/">
        <img src="@/assets/logo/logo_color.svg">
      </CHeaderBrand>
      <CHeaderNav class="d-none d-md-flex me-auto">
        <CNavItem>
          <router-link to="/dashboard" class="nav-link">概要</router-link>
        </CNavItem>
        <CNavItem>
          <router-link to="/user/list" class="nav-link">ユーザー</router-link>
        </CNavItem>
        <CNavItem>
          <router-link to="/cog/default" class="nav-link">設定</router-link>
        </CNavItem>
      </CHeaderNav>
      <CHeaderNav>
        <AppHeaderDropdownAccnt/>
      </CHeaderNav>
    </CContainer>
    <CHeaderDivider/>
    <CContainer fluid>
      <AppBreadcrumb/>
    </CContainer>
  </CHeader>
</template>

<script>
import AppBreadcrumb from './AppBreadcrumb'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import {logo} from '@/assets/brand/logo'

export default {
  name: 'AppHeader',
  components: {
    AppBreadcrumb,
    AppHeaderDropdownAccnt,
  },
  setup() {
    return {
      logo,
    }
  },
}
</script>
