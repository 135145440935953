import { getLocales } from "../locales";
import { l, t } from "../translation";
function getNumberFormat(options = {}) {
    return Intl.NumberFormat(getLocales(), options);
}
export const numberPartsItem = l({
    fallback: (v, options = {}) => {
        return getNumberFormat(options).formatToParts(v);
    },
});
export function number(v, options = {}) {
    return t(numberPartsItem)(v, options)
        .map((item) => item.value)
        .join("");
}
export function numberParts(v, options = {}) {
    return t(numberPartsItem)(v, options);
}
